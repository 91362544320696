import { CheckCircle, Delete } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Grid, Modal, Switch, Tooltip, Typography } from "@mui/material";
import { arrayMoveImmutable } from "array-move";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Draggable } from "react-smooth-dnd";
import { toast } from "react-toastify";
import FollowUpStep from "./components/FollowUpStep";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    border: 0,
    borderRadius: 2,
    boxShadow: 24,
    px: 4,
    py: 4,
};

function FollowUpSteps({ formData }) {
    const {
        formField: { followUpGroups, defaultFollowUpGroup },
    } = formData;

    const { values, setFieldValue } = useFormikContext();

    const {
        followUpGroups: followUpGroupsValue,
        defaultFollowUpGroup: defaultFollowUpGroupValue,
        conversationStepGroups: conversationStepGroupsValue,
    } = values;

    const [isGroupModalOpen, setIsGroupModelOpen] = useState(false);
    const [isStepModalOpen, setIsStepModelOpen] = useState(false);

    const [selectedGroupIdentifier, setSelectedGroupIdentifier] = useState(
        followUpGroupsValue[0]?.identifier || null
    );
    const [
        selectedGroupIdentifierForEditing,
        setSelectedGroupIdentifierForEditing,
    ] = useState(null);
    const [selectedStepIndex, setSelectedStepIndex] = useState(null);

    const [groupData, setGroupData] = useState(null);
    const [stepData, setStepData] = useState(null);

    const { assistantId } = useParams();

    const selectedGroup = useMemo(() => {
        return followUpGroupsValue.find(
            (_) => _.identifier === selectedGroupIdentifier
        );
    }, [selectedGroupIdentifier, followUpGroupsValue]);

    const sortedFollowUpGroups = useMemo(() => {
        return [...(followUpGroupsValue.slice() || [])].sort((a, b) => {
            if (a.category === "General" && b.category !== "General") return -1;
            if (b.category === "General" && a.category !== "General") return 1;

            return 0;
        });
    }, [followUpGroupsValue]);

    const toggleGroupModal = () => {
        setIsGroupModelOpen(!isGroupModalOpen);
    };

    const toggleStepModal = () => {
        setIsStepModelOpen(!isStepModalOpen);
    };

    useEffect(() => {
        if (!defaultFollowUpGroupValue) {
            setFieldValue(
                defaultFollowUpGroup.name,
                followUpGroupsValue[0]?.identifier || null
            );
        }
    }, [defaultFollowUpGroupValue]);

    const renderedFollowUpGroups = useMemo(() => {
        return sortedFollowUpGroups.map((followUpGroup, index) => {
            const isSubjectForHeading =
                (index - 1 >= 0 &&
                    followUpGroup.category !==
                        sortedFollowUpGroups[index - 1].category &&
                    followUpGroup.category !== "General") ||
                (index === 0 &&
                    sortedFollowUpGroups.filter((_) => _.category === "General")
                        .length === 0);

            const isLastGroupOfGeneralCategory =
                index ===
                sortedFollowUpGroups.findLastIndex(
                    (_) => _.category === "General"
                );

            const isGroupActive =
                selectedGroupIdentifier === followUpGroup.identifier;

            const isDefault =
                defaultFollowUpGroupValue === followUpGroup.identifier;
            const isEditable = followUpGroup.category === "General";
            const isRemovable =
                followUpGroup.category === "General" && !isDefault;

            return (
                <>
                    {isSubjectForHeading && (
                        <SoftBox
                            mt={1}
                            mx={0.5}
                            lineHeight={0}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="medium"
                                textTransform="capitalize"
                            >
                                {followUpGroup.category}
                            </SoftTypography>
                        </SoftBox>
                    )}

                    <SoftBox
                        key={`follow-up-group-${followUpGroup.identifier}`}
                        sx={{
                            borderRadius: "0.375rem",
                            padding: "0.5rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            cursor: "pointer",
                            backgroundColor: isGroupActive
                                ? "#1C4C73"
                                : "#E6EAF0",
                            color: isGroupActive ? "#fff" : "#000",
                        }}
                        onClick={() => {
                            setSelectedGroupIdentifier(
                                followUpGroup.identifier
                            );
                        }}
                    >
                        <SoftTypography
                            variant="caption"
                            textTransform="capitalize"
                            color={isGroupActive ? "#fff" : "#000"}
                        >
                            {followUpGroup.name} {isDefault ? "(Default)" : ""}
                        </SoftTypography>
                        <SoftBox
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                p: 0,
                                gap: 1,
                            }}
                            color={isGroupActive ? "#fff" : "#000"}
                        >
                            {!isDefault && (
                                <Tooltip title="Set As Default" placement="top">
                                    <CheckCircle
                                        onClick={(e) => {
                                            e.stopPropagation();

                                            setFieldValue(
                                                defaultFollowUpGroup.name,
                                                followUpGroup.identifier
                                            );

                                            toast.success(
                                                "Follow-up Group set as default."
                                            );

                                            setSelectedGroupIdentifier(
                                                followUpGroup.identifier
                                            );
                                        }}
                                        fontSize="small"
                                    />
                                </Tooltip>
                            )}
                            {isEditable ? (
                                <Tooltip title="Edit" placement="top">
                                    <EditIcon
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setSelectedGroupIdentifierForEditing(
                                                followUpGroup.identifier
                                            );
                                            setGroupData(followUpGroup);
                                            toggleGroupModal();
                                        }}
                                        fontSize="small"
                                    />
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                            {isRemovable ? (
                                <Tooltip title="Remove" placement="top">
                                    <Delete
                                        onClick={(e) => {
                                            e.stopPropagation();

                                            for (const _ of conversationStepGroupsValue) {
                                                if (
                                                    _.steps.filter(
                                                        (step) =>
                                                            step.type ===
                                                                "change_follow_up_group" &&
                                                            step.content ===
                                                                followUpGroup.identifier
                                                    ).length > 0 ||
                                                    _.steps.filter(
                                                        (step) =>
                                                            step.type ===
                                                                "conditional" &&
                                                            step.steps.filter(
                                                                (step) =>
                                                                    step.type ===
                                                                        "change_follow_up_group" &&
                                                                    step.content ===
                                                                        followUpGroup.identifier
                                                            ).length > 0
                                                    ).length > 0
                                                ) {
                                                    return toast.error(
                                                        "This group is being used in some steps."
                                                    );
                                                }
                                            }

                                            setFieldValue(
                                                followUpGroups.name,
                                                followUpGroupsValue.filter(
                                                    (_) =>
                                                        _.identifier !==
                                                        followUpGroup.identifier
                                                )
                                            );

                                            setSelectedGroupIdentifier(
                                                followUpGroupsValue[0]
                                                    ?.identifier || null
                                            );

                                            toast.success(
                                                "Follow-Up Group removed."
                                            );
                                        }}
                                        fontSize="small"
                                    />
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                        </SoftBox>
                    </SoftBox>

                    {isLastGroupOfGeneralCategory ? (
                        <SoftBox
                            sx={{
                                borderRadius: "0.375rem",
                                padding: "0.5rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                cursor: "pointer",
                                backgroundColor: "#1C4C73",
                                color: "#fff",
                            }}
                            onClick={() => {
                                setGroupData({
                                    name: "",
                                    category: "General",
                                    steps: [],
                                });

                                setSelectedGroupIdentifierForEditing(null);

                                toggleGroupModal();
                            }}
                        >
                            <AddIcon fontSize="small" />
                            <SoftTypography
                                variant="caption"
                                textTransform="capitalize"
                                color="#fff"
                            >
                                Add Follow-Up Group
                            </SoftTypography>
                            <SoftBox
                                sx={{
                                    width: "20px",
                                }}
                            />
                        </SoftBox>
                    ) : null}
                </>
            );
        });
    }, [
        sortedFollowUpGroups,
        selectedGroupIdentifier,
        defaultFollowUpGroupValue,
    ]);

    const intervalUnits = [
        {
            label: "minute/s",
            value: "minute",
        },
        {
            label: "hour/s",
            value: "hour",
        },
        {
            label: "day/s",
            value: "day",
        },
    ];

    return (
        <SoftBox>
            <SoftBox lineHeight={0}>
                <SoftTypography variant="h5" fontWeight="bold">
                    Follow-Up Groups
                </SoftTypography>
                <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                >
                    Add follow-up groups to your assistant. Follow-up groups are
                    a collection of follow-up steps that can be assigned to your
                    assistant.
                </SoftTypography>
            </SoftBox>

            <SoftBox mt={1.625}>
                <Grid mt={1.625} item xs={12}>
                    <SoftBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                    >
                        <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                        >
                            Follow-Up Groups
                        </SoftTypography>
                    </SoftBox>
                    <Grid container spacing={1}>
                        <Grid
                            item
                            xs={3}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                            }}
                        >
                            {renderedFollowUpGroups}
                        </Grid>
                        <Grid item xs={9}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0}>
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Follow-Up Steps
                                </SoftTypography>
                            </SoftBox>
                            {selectedGroup?.steps?.length > 0 && (
                                <Container
                                    dragHandleSelector=".drag-handle"
                                    groupName="parent"
                                    onDrop={({ removedIndex, addedIndex }) => {
                                        setFieldValue(
                                            followUpGroups.name,
                                            followUpGroupsValue.map((group) => {
                                                if (
                                                    group.identifier ===
                                                    selectedGroup.identifier
                                                ) {
                                                    return {
                                                        ...group,
                                                        steps: arrayMoveImmutable(
                                                            group.steps,
                                                            removedIndex,
                                                            addedIndex
                                                        ),
                                                    };
                                                } else {
                                                    return group;
                                                }
                                            })
                                        );
                                    }}
                                >
                                    {followUpGroupsValue.filter(
                                        (_) => _.category === "General"
                                    ).length === 0 && (
                                        <SoftBox
                                            sx={{
                                                borderRadius: "0.375rem",
                                                padding: "0.5rem",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                cursor: "pointer",
                                                backgroundColor: "#1C4C73",
                                                color: "#fff",
                                            }}
                                            onClick={() => {
                                                setGroupData({
                                                    name: "",
                                                    category: "General",
                                                    steps: [],
                                                });

                                                setSelectedGroupIdentifierForEditing(
                                                    null
                                                );

                                                toggleGroupModal();
                                            }}
                                        >
                                            <AddIcon fontSize="small" />
                                            <SoftTypography
                                                variant="caption"
                                                textTransform="capitalize"
                                                color="#fff"
                                            >
                                                Add Follow-Up Group
                                            </SoftTypography>
                                            <SoftBox
                                                sx={{
                                                    width: "20px",
                                                }}
                                            />
                                        </SoftBox>
                                    )}
                                    {(selectedGroup?.steps || []).map(
                                        (step, index) => {
                                            return (
                                                <Draggable key={index}>
                                                    <FollowUpStep
                                                        key={`follow-up-step-${index}`}
                                                        index={index}
                                                        step={step}
                                                        onChange={(_) => {
                                                            setSelectedStepIndex(
                                                                index
                                                            );
                                                            setStepData(
                                                                selectedGroup
                                                                    ?.steps[
                                                                    index
                                                                ]
                                                            );

                                                            toggleStepModal();
                                                        }}
                                                        onDelete={(_) => {
                                                            setFieldValue(
                                                                followUpGroups.name,
                                                                followUpGroupsValue.map(
                                                                    (group) => {
                                                                        if (
                                                                            group.identifier ===
                                                                            selectedGroup.identifier
                                                                        ) {
                                                                            return {
                                                                                ...group,
                                                                                steps: group.steps.filter(
                                                                                    (
                                                                                        _,
                                                                                        i
                                                                                    ) =>
                                                                                        i !==
                                                                                        index
                                                                                ),
                                                                            };
                                                                        } else {
                                                                            return group;
                                                                        }
                                                                    }
                                                                )
                                                            );
                                                        }}
                                                    />
                                                </Draggable>
                                            );
                                        }
                                    )}
                                </Container>
                            )}

                            <SoftBox
                                sx={{
                                    py: 1,
                                    borderRadius: "0.375rem",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#1C4C73",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setSelectedStepIndex(null);
                                    setStepData({
                                        interval: 1,
                                        intervalUnit: intervalUnits[2].value,
                                        message: "",
                                    });

                                    toggleStepModal();
                                }}
                            >
                                <SoftTypography
                                    variant="body2"
                                    fontWeight="regular"
                                    color="white"
                                >
                                    Add Follow-Up Step
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                    </Grid>
                </Grid>
                <Modal open={isGroupModalOpen} onClose={toggleGroupModal}>
                    <Box
                        sx={{
                            ...style,
                        }}
                    >
                        <SoftTypography variant="body1">
                            {selectedGroupIdentifierForEditing === null
                                ? "Add"
                                : "Edit"}{" "}
                            Follow-Up Group
                        </SoftTypography>

                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block"
                        >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                            >
                                Name
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput
                            label="Name"
                            placeholder="Enter name"
                            value={groupData?.name}
                            onChange={(event) => {
                                setGroupData((_) => ({
                                    ..._,
                                    name: event.target.value,
                                }));
                            }}
                        />

                        <Box
                            sx={{
                                marginTop: 2,
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    borderRadius: 1,
                                    bgcolor: "#1C4C73",
                                    color: "#fff",
                                    px: 3,
                                    py: 0.6,
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    if (!groupData?.name) {
                                        return toast.error("Name is required.");
                                    }

                                    if (!groupData?.category) {
                                        return toast.error(
                                            "Category is required."
                                        );
                                    }

                                    if (groupData?.category !== "General") {
                                        return toast.error(
                                            "Category is invalid."
                                        );
                                    }

                                    if (!Array.isArray(groupData?.steps)) {
                                        return toast.error(
                                            "Steps must be an array."
                                        );
                                    }

                                    if (
                                        selectedGroupIdentifierForEditing ===
                                        null
                                    ) {
                                        const temporaryGroupIdentifier =
                                            crypto.randomUUID();

                                        setFieldValue(followUpGroups.name, [
                                            ...followUpGroupsValue,
                                            {
                                                identifier:
                                                    temporaryGroupIdentifier,
                                                ...groupData,
                                            },
                                        ]);

                                        setSelectedGroupIdentifier(
                                            temporaryGroupIdentifier
                                        );
                                    } else {
                                        setFieldValue(
                                            followUpGroups.name,
                                            followUpGroupsValue.map((group) => {
                                                if (
                                                    group.identifier ===
                                                    selectedGroupIdentifierForEditing
                                                ) {
                                                    return groupData;
                                                }

                                                return group;
                                            })
                                        );

                                        setSelectedGroupIdentifier(
                                            selectedGroupIdentifierForEditing
                                        );

                                        setSelectedGroupIdentifierForEditing(
                                            null
                                        );
                                    }

                                    toggleGroupModal();
                                }}
                            >
                                {selectedGroupIdentifierForEditing === null
                                    ? "Create"
                                    : "Update"}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#1C4C73",
                                    px: 3,
                                    py: 0.6,
                                    cursor: "pointer",
                                }}
                                onClick={toggleGroupModal}
                            >
                                Close
                            </Typography>
                        </Box>
                    </Box>
                </Modal>
                <Modal open={isStepModalOpen} onClose={toggleStepModal}>
                    <Box
                        sx={{
                            ...style,
                        }}
                    >
                        <SoftTypography variant="body1">
                            {selectedStepIndex === null ? "Add" : "Edit"} Follow
                            Up Step
                        </SoftTypography>

                        <SoftBox
                            sx={{
                                display: "flex",
                                alignItems: "end",
                                justifyContent: "space-between",
                                gap: 1,
                            }}
                        >
                            <SoftBox>
                                <SoftBox
                                    mb={1}
                                    ml={0.5}
                                    lineHeight={0}
                                    display="inline-block"
                                >
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Interval
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput
                                    label="Interval"
                                    placeholder="Enter Interval"
                                    value={stepData?.interval}
                                    type="number"
                                    onChange={(event) => {
                                        setStepData((_) => ({
                                            ..._,
                                            interval: parseInt(
                                                event.target.value
                                            ),
                                        }));
                                    }}
                                />
                            </SoftBox>
                            <SoftSelect
                                options={intervalUnits}
                                value={intervalUnits.filter(
                                    (_) => _.value === stepData?.intervalUnit
                                )}
                                onChange={({ value }) => {
                                    setStepData((_) => ({
                                        ..._,
                                        intervalUnit: value,
                                    }));
                                }}
                            />
                        </SoftBox>

                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block"
                        >
                            <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                            >
                                Instructions
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput
                            label="Instructions"
                            placeholder="Enter Instructions"
                            value={stepData?.message}
                            multiline
                            rows={3}
                            onChange={(event) => {
                                setStepData((_) => ({
                                    ..._,
                                    message: event.target.value,
                                }));
                            }}
                        />

                        <SoftBox
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                mt: 1,
                            }}
                        >
                            <SoftBox
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                            >
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    Reset To Step After Reply
                                </SoftTypography>
                            </SoftBox>
                            <Switch
                                checked={
                                    typeof stepData?.resetToStepAfterReply ===
                                    "number"
                                }
                                onChange={() => {
                                    setStepData((_) => ({
                                        ..._,
                                        resetToStepAfterReply:
                                            typeof stepData?.resetToStepAfterReply ===
                                            "number"
                                                ? null
                                                : 1,
                                    }));
                                }}
                            />
                        </SoftBox>

                        {typeof stepData?.resetToStepAfterReply ===
                            "number" && (
                            <SoftInput
                                label="Reset To Step After Reply"
                                placeholder="Enter Reset To Step After Reply"
                                value={stepData?.resetToStepAfterReply}
                                type="number"
                                onChange={(event) => {
                                    setStepData((_) => ({
                                        ..._,
                                        resetToStepAfterReply: parseInt(
                                            event.target.value
                                        ),
                                    }));
                                }}
                            />
                        )}

                        <SoftBox
                            ml={0.5}
                            mt={0.5}
                            lineHeight={0}
                            display="inline-block"
                        >
                            <SoftTypography variant="caption">
                                With this enabled, the assistant will reset
                                currently active follow-up step to the specified
                                step number after the user replies to the
                                current step.
                            </SoftTypography>
                        </SoftBox>

                        <Box
                            sx={{
                                marginTop: 2,
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    borderRadius: 1,
                                    bgcolor: "#1C4C73",
                                    color: "#fff",
                                    px: 3,
                                    py: 0.6,
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    if (!stepData?.interval) {
                                        return toast.error(
                                            "Interval is required."
                                        );
                                    }

                                    if (
                                        typeof stepData?.interval !== "number"
                                    ) {
                                        return toast.error(
                                            "Interval must be a number."
                                        );
                                    }

                                    if (!stepData?.intervalUnit) {
                                        return toast.error(
                                            "Interval unit is required."
                                        );
                                    }

                                    if (
                                        !intervalUnits.find(
                                            (_) =>
                                                _.value ===
                                                stepData?.intervalUnit
                                        )
                                    ) {
                                        return toast.error(
                                            "Invalid interval unit."
                                        );
                                    }

                                    if (!stepData?.message) {
                                        return toast.error(
                                            "Instruction is required."
                                        );
                                    }

                                    if (
                                        typeof stepData?.resetToStepAfterReply ===
                                        "number"
                                    ) {
                                        if (
                                            Number(
                                                isNaN(
                                                    stepData?.resetToStepAfterReply
                                                )
                                            )
                                        ) {
                                            return toast.error(
                                                "Reset to step after reply is required if enabled."
                                            );
                                        }

                                        if (
                                            stepData?.resetToStepAfterReply < 0
                                        ) {
                                            return toast.error(
                                                "Reset to step after reply must be greater than 0."
                                            );
                                        }
                                    } else {
                                        setStepData((_) => ({
                                            ..._,
                                            resetToStepAfterReply: null,
                                        }));
                                    }

                                    if (selectedStepIndex === null) {
                                        setFieldValue(
                                            followUpGroups.name,
                                            followUpGroupsValue.map((group) => {
                                                if (
                                                    group.identifier ===
                                                    selectedGroup.identifier
                                                ) {
                                                    return {
                                                        ...group,
                                                        steps: [
                                                            ...group.steps,
                                                            stepData,
                                                        ],
                                                    };
                                                } else {
                                                    return group;
                                                }
                                            })
                                        );

                                        toggleStepModal();
                                    } else {
                                        setFieldValue(
                                            followUpGroups.name,
                                            followUpGroupsValue.map((group) => {
                                                if (
                                                    group.identifier ===
                                                    selectedGroup.identifier
                                                ) {
                                                    return {
                                                        ...group,
                                                        steps: group.steps.map(
                                                            (_, i) => {
                                                                if (
                                                                    i ===
                                                                    selectedStepIndex
                                                                ) {
                                                                    return stepData;
                                                                }

                                                                return _;
                                                            }
                                                        ),
                                                    };
                                                }

                                                return group;
                                            })
                                        );

                                        toggleStepModal();
                                    }
                                }}
                            >
                                {selectedStepIndex === null
                                    ? "Create"
                                    : "Update"}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#1C4C73",
                                    px: 3,
                                    py: 0.6,
                                    cursor: "pointer",
                                }}
                                onClick={toggleStepModal}
                            >
                                Close
                            </Typography>
                        </Box>
                    </Box>
                </Modal>
            </SoftBox>
        </SoftBox>
    );
}

FollowUpSteps.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
};

export default FollowUpSteps;
